<div class="header">
    <h1 mat-dialog-title>{{data?.title}}</h1>
    <button class="btn btn-light" mat-icon-button [mat-dialog-close]="false">
        <mat-icon >close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <p style="white-space: pre-wrap;">{{data?.message}}</p>
</div>

<div *ngIf="!(data.cancelCaption==''&&data.confirmCaption=='')" mat-dialog-actions [align]="'end'">
    <button style="margin-right: 10px;" class="btn btn-info" mat-raised-button [mat-dialog-close]="false">
        {{data?.cancelCaption}}
    </button>
    <button class="btn btn-info" mat-raised-button  [mat-dialog-close]="true">
        {{data?.confirmCaption}}
    </button>
</div>