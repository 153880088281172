import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {RegisterComponent} from '../register/register.component'



@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {

  constructor(private reg: RegisterComponent) { }

  ngOnInit(): void {
  }
  buttonClick(){
    
    this.reg.close();
  }

}
