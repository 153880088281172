
<div id="thankyouCard" class="thankyou_card">
    <div class="close-button">
      <button type="button" (click)="buttonClick()"  class="btn-close" ></button>
    </div>
    <div class="thankyou-container">
      <div class="thankyou-image">
        <img src="../../assets/images/img/thankyou.svg">
      </div>
      <div class="thankyou-text">
        <p style="font-size: 50px; color: #686868; font-weight: 600;">Thank you!</p>
      </div>
      <div class="thakyou-small text">
        <p style="text-align: center;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut</p>
      </div>
    </div>
  </div>
  