<!-- <script src="https://www.google.com/recaptcha/api.js"></script> -->
<script>
    function onSubmit(token) {
      document.getElementById("demo-form").submit();
    }
  </script>
  <div  id="signupWrapper" class="signup-wrapper">
                <div class="left-side-container">
                      <div class="signup-welcome-wrapper">
                        <img class="wardrobe-logo" src="../../assets/images/img/wardrobelogo.png">
                        <div class="signup-welcome-text">
                          <p class="welcomeback">Welcome Back!</p>
                          <p class="register-to-continue">Register to continue</p>
                        </div>
                      </div>
                      <img src="../../assets/images/img/signup.png"
                        class="img-fluid signup-image" alt="Sample image">
      
                </div>
                <div  class="row justify-content-center right-side-container">
                  <div class=form-wrapper *ngIf="signupSubmit">
                  <div class="col-md-8 col-lg-8 col-xl-8 order-2 order-lg-1">
                    <div class="form-header">
                    <img class="signup_vector" src="../../assets/images/img/signupvector.svg">
                    <p class="create-enq">Create Enquiry</p>
                    <p class="header-txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
  
                    </div>
                    <form [formGroup]="registerForm"  a class="mx-1 mx-md-4">
    
                      <div class="d-flex flex-row align-items-center mb-4">
                        
                        <div class="form-outline flex-fill mb-0">
                          <input type="text" id="yourName" class="form-control" placeholder="Your name"/>
                          
                        </div>
                      </div>
    
                      <div class="d-flex flex-row align-items-center mb-4">
                        
                        <div class="form-outline flex-fill mb-0">
                          <input type="email" id="emailAdd" class="form-control" placeholder="Your Email address" />
                          
                        </div>
                      </div>
    
                      <div class="d-flex flex-row align-items-center mb-4">
                        
                        <div class="form-outline flex-fill mb-0">
                          <input type="number" id="form3Example4c" class="form-control" placeholder="Phone" />
                          
                        </div>
                      </div>
    
                      <div class="d-flex flex-row align-items-center mb-4">
                        
                          <div class="form-outline flex-fill mb-0">
                            <input type="text" id="bussinessName" class="form-control" placeholder="Bussiness name"/>
                            
                          </div>
                      </div>
                      <div class="d-flex flex-row align-items-center mb-4">
                        
                          <div class="form-outline flex-fill mb-0">
                            <input type="text" id="address" class="form-control" placeholder="Bussiness address"/>
                            
                          </div>
                      </div>
                      <div class="d-flex flex-row align-items-center mb-4">
                        
                          <div class="form-outline flex-fill mb-0">
                            <input type="text" id="time" class="form-control" placeholder="Good time to contact you"/>
                            
                          </div>
                      </div>
                      <div class="captcha">
                          <ngx-recaptcha2 class="g-recaptcha" #captchaElem
                            
          
                              formControlName="recaptcha">
                          </ngx-recaptcha2>
                    </div>
                      <div class="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                        <button (click)="enquiry()" type="button" class="btn btn-primary submit-button">Submit</button>
                      </div>
    
                    </form>
    
                  </div>
                  
                  </div>
                  <div *ngIf="!signupSubmit" >
                  
                    <app-thankyou></app-thankyou> 
                  </div>
                  
  </div>
             