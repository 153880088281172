<div class="body-class">    
    <div class="header-login">
        <div class="logo-wrapper">
            <img alt="bleh" src="../../assets/images/img/welcome.png">    
        </div>
    </div>
    <div class="login-container-outside">
        <form [formGroup]="loginForm" (ngSubmit)="tryLogin()" >  
            <div class="login-container-inside">   
                <label for="username" class="label-style" >User Name </label>  
                <input formControlName="userName"  class="input-style" id="userName"  type="text" placeholder="Enter user name" name="username"  required>
                <!-- <div class="Validation-class" *ngIf="userName.touched && !userName.valid">User name is required !</div> -->
                
                <label class="label-style" for="passWord">Password </label>   
                <input formControlName="password"   id="passWord" class="input-style" type="password" placeholder="Enter Password" name="password" required> 
                <!-- <div class="Validation-class" *ngIf="passWord.touched && !passWord.valid">
                    <div *ngIf="passWord.errors?.required">Password is required !</div>
                    <div *ngIf="passWord.errors?.minlength">Password Should be minimum 8 characters long !</div>
                </div> -->
                
                <div style="margin-top: 2rem;" *ngIf="log_status"><p class="invalid_cred">Invalid credentials !!</p></div>
                <a class="forgot-password" href="#">Forgot password </a>  
                <button class="login-btn" type="submit">Login</button>   
                
            </div>   
        </form>  

    </div>
    <div class="footer-login">
        <div class="footer-wrapper">
            <p class="footer-links">Book Publisher/Distributor? Click <a style="text-decoration: none;" routerLink="/register">here</a> to Publish your books on Digilib. <a>Terms and Conditions</a> | <a>Privacy Policy</a></p>
        </div>
    </div>
</div>
