import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { id } from '@swimlane/ngx-datatable';
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { SpinnerService } from '../digilibcomponents/spinner/spinner.service'
@Injectable({
  providedIn: 'root'
})
export class TokeninterceptorService implements HttpInterceptor{

  constructor(private spinnerService: SpinnerService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    let jwttoken;
    let token= localStorage.getItem('token');
    if(token!=null){
     jwttoken=req.clone({
      setHeaders:{
        token: token
      }
      })
      }
    this.spinnerService.requestStarted();
    return next.handle(jwttoken).pipe(tap(
      (event) => {
      if(event instanceof HttpResponse){
        this.spinnerService.requestEnded();
      }
    },
    (error: HttpErrorResponse) =>{
      throw error;
    }
    
    ),
    );
  }
}
